::-webkit-scrollbar {
    width: 8px; 
    height: 10px;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* font-family: "Montserrat"; */
}
::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px; 
}
li{
    list-style-type: none;
}
a{
    text-decoration: none;
}
::-webkit-scrollbar-thumb {
    background: #3366ff;
    border-radius: 10px; 
}


::-webkit-scrollbar-thumb:hover {
    background: #c73535;
}