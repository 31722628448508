.home_background {
    background-image: linear-gradient(111.84deg, rgba(1, 57, 89, 0.91) 42.41%, rgba(115, 146, 164, 0.653055) 60.07%, rgba(255, 255, 255, 0.34) 74.54%, #FFFFFF 101.24%), url(../../../Images/Home/home-slider.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 15vw;
}

.main-home-container {
    background-color: #F4F5F8;
}

.home_text {
    margin-top: 10vw;
    margin-bottom: 3vw;
}

.home_text h3 {
    font-size: 3.5vw;
    font-family: "Marcellus";
    color: #fff;
    margin-bottom: 0px;
}

.home_text h4 {
    font-size: 6vw;
    font-family: "Marcellus";
    color: #fff;
}

.home_text h4 span {
    font-size: 6vw;
    font-family: "Marcellus";
    color: #EF3A43;
}

.home_text p {
    font-size: 1vw;
    color: #fff;
    width: 47%;
    margin-bottom: 0px;
}

.home_request_quote {
    display: flex;
    align-items: center;
    gap: 40px;
}

.home_page_request {
    background-color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 30px;
    width: 18%;
    box-shadow: 0px 5px #000;
}

.home_page_request:hover {
    box-shadow: 0px 5px #EF3A43;
}

.home_page_request a {
    color: #124561;
    text-decoration: none;
    font-size: 1vw;
    font-weight: 600;
    font-family: "Montserrat";
}

.home_page_call h6 {
    font-size: 1vw;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 600;
    text-align: center;
}

.home_page_call p {
    font-size: 1vw;
    color: #fff;
    margin-bottom: 0px;
}

.trusted_companies_d_f {
    display: flex;
    align-items: center;
}

.group_image img {
    margin-top: -7vw;
    width: 90%;
}

.group_logo h2 {
    font-size: 2vw;
    font-weight: 600;
    color: #000000;
}

.group_logo h2 span {
    color: #DA031B;
}

.group_logo_wrapper img {
    width: 15%;
}

.group_logo_wrapper {
    display: flex;
    gap: 5px;
}

.home_about {
    margin-top: 4vw;
}

.home_about h6 {
    font-size: 1vw;
    color: #183B68;
    margin-bottom: 0px;
}

.home_about h3 {
    font-size: 1.8vw;
    color: #DA031B;
    margin-top: 15px;
    font-weight: 600;
}

.home_about p {
    font-size: 1vw;
    color: #000000;
    text-align: justify;
    margin-bottom: 0px;
}

.home_save_your_money_d_f {
    display: flex;
    gap: 20px;
}

.home_save_your_money {
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.home_save_your_money_text {
    background-color: #EAEFF3;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
}

.home_save_your_money_text img {
    width: 55px;
}

.home_save_your_money_text h5 {
    font-size: 1vw;
    margin-top: 20px;
    font-weight: 600;
}

.home_save_your_money_text p {
    margin-bottom: 0px;
    font-size: 1vw;
}

.home_why_choose_us {
    padding-top: 3vw;
}

.home_why_choose_us h6 {
    font-size: 1vw;
    color: #183B68;
}

.home_why_choose_us h4 {
    font-size: 1.8vw;
    font-weight: 600;
    color: #DA031B;
    margin-bottom: 30px;
}

.trusted_partners_box img {
    width: 15%;
    margin: 0 auto;
}

.trusted_partners_box {
    background-color: #183B68;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    padding: 10px 0px 10px 0px;
    margin-top: 2vw;
    border: 2px solid #E4BC7E;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.trusted_partners_box h3 {
    color: #ffff;
    font-size: 1vw;
    margin-top: 10px;
}

.trusted_partners_box a {
    color: #ffff;
    font-size: 1vw;
}

.insurance_services img {
    width: 100%;
}

.get_free_estimate {
    background-color: #EDEDED;
    width: 80%;
    margin: 10vw auto 0;
    border-radius: 20px;

    padding: 30px;
    margin-bottom: 3vw;
}

.get_estimate_post img {
    width: 85%;
}

.get_free_estimate_d_f {
    display: flex;
}

.get_free_estimate h3 {
    margin-top: 2vw;
    font-size: 1vw;
    color: #183B68;
}

.get_free_estimate h4 {
    font-size: 1.8vw;
    font-weight: 600;
    color: #DA031B;
    margin-bottom: 30px;
}

/* home-input */

/* input[type="text"] {
    width: 100%;
    border: none;
    border-radius: 4px;
    margin: 8px 0;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
} */


.inputWithIcon input{
padding: 4px 40px;
width: 100%;
border: none;
outline: none;
border-radius: 5px;
}


.inputWithIcon {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    border:2px solid #dee2e6;
}

.inputWithIcon svg {
    position: absolute;
    left: 10px;
    
    color: #0066CC;
    transition: 0.3s;
    font-size: 16px;
}

.input-group-text svg {
    color: #0066CC;
    transition: 0.3s;
    font-size: 16px;
}

.inputWithIcon input:focus+i {
    color: dodgerBlue;
}

/* home-input-end */

.Get_Quote button {
    background-color: #0066CC;
    padding: 8px;
    width: 25%;
    border-radius: 10px;
    color: #fff;
    font-size: 1vw;
    font-weight: 600;
    border: none;
    box-shadow: 0px 5px #000;
}

.Get_Quote button:hover {
    box-shadow: 0px 5px #DA031B;
}

.Get_Quote {
    margin-top: 2vw;
}

.health_insurance {
    height: auto;
    background-color: #2C5A95;
    padding-bottom: 25px;
    padding-top: 40px;
}

.health_insurance .slider {
    margin: 0 0px;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.slider .image-items {
    height: 100%;
}

.slider .image-items .image {
    height: 100%;
}

.slider .image-items .image img {
    height: 100%;
    display: block;
    width: 15%;
    object-fit: cover;
}

.arrowButton {
    outline: none;
    border: 0;
    color: #fff !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    background-color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrowButton>svg {
    color: #2C5A95;
    font-size: 20px;
    font-weight: 400;
}

.listed_company_dialog_imgaes {
    display: block;
    width: 100%;
}

@media only screen and (max-width:868px) {
    .slider {
        margin: 0 20px;
    }
}

.Vehicle_insurance h3 {
    font-size: 2.5vw;
    color: #fff;
    font-family: "Marcellus";
}

.Vehicle_insurance p {
    font-size: 1vw;
    color: #fff;
    margin-bottom: 1vw;
}

.home_insurance_slider_text {
    width: 100%;
    background-color: #ffff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid #aaa;
}

.home_insurance_slider_text h3 {
    font-size: 1vw;
    font-weight: 600;
    margin-top: 10px;
}

.home_insurance_slider_text p {
    font-size: 1vw;
}

.home_insurance_slider_text a {
    font-size: 1vw;
    color: #183B68;
    text-decoration: none;
    font-weight: 600;
}

.slider-btn-top {
    position: relative;
    display: flex;
    gap: 4px;
}

.slider-top-heading-btn {
    display: flex;
    justify-content: space-between;
}

.swiper-slide {
    margin-right: 5px;
}

/* testimonials */
.testimonials {
    height: auto;
    margin-top: 4vw;
}

.items_slider_padding {
    margin-bottom: 20px;
    margin-top: 10px;
}

.testimonials .slider {
    margin: 0 0px;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.testimonials_insurance h3 {
    font-size: 1.8vw;
    font-weight: 600;
    color: #DA031B;
}

.testimonials_insurance p {
    font-size: 1vw;
    color: #000;
    margin-bottom: 1vw;
}

img.testimonials_laura {
    width: 35px !important;
    height: 35px !important;
    border-radius: 100% !important;
}

.testimonials_home {
    display: flex;
    align-items: center;
    gap: 10px;
}

.testimonials_home h6 {
    font-size: 1vw;
    margin-bottom: 0px;
    font-weight: 500;
}

img.silder_star {
    width: 45% !important;
}

.home_blog_sec {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}

.home_blog_sec h6 {
    font-size: 14px;
    color: #64607D;
    margin-bottom: 0px;
}

.home_our_blogs h3 {
    font-size: 1.2vw;
    font-weight: 600;
    margin-top: 10px;
}

.home_our_blogs p {
    font-size: 1vw;
    margin-bottom: 0px;
}

.home_our_blogs img {
    width: 100%;
}

.titel_our_blogs {
    text-align: end;
    margin-bottom: 2vw;
}

.titel_our_blogs h2 {
    color: #DA031B;
    font-weight: 700;
    font-size: 2vw;
    margin-bottom: 0px;
}

.titel_our_blogs p {
    font-size: 1vw;
    margin-bottom: 0px;
}

.navigating_insurance h4 {
    font-size: 1vw;
    margin-top: 5px;
    font-weight: 600;
    margin-bottom: 0px;
}

.navigating_insurance img {
    width: 100%;
}

.boder {
    border-bottom: 2px solid #DEE1E6;
    padding-bottom: 20px;
    margin-bottom: 2vw;
}



.home_view_more:hover {
    box-shadow: 0px 5px #DA031B;
}

.home_view_more a {
    color: #ffff;
    text-decoration: none;
    font-size: 1vw;
    font-weight: 500;
}

.home_our_blogs_background {
    margin-bottom: 3vw;
    margin-top: 3vw;
}

/* end */

.home_our_blogs_background {
    margin-bottom: 3vw;
    margin-top: 3vw;
}

.home_our_blogs h3 {
    font-size: 1.2vw;
    font-weight: 600;
    margin-top: 10px;
}

.home_our_blogs p {
    font-size: 1vw;
    margin-bottom: 0px;
}

.home_our_blogs img {
    width: 100%;
    height: 200px;
}

.home_blog_sec {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}

.home_blog_sec h6 {
    font-size: 1vw;
    color: #64607D;
    margin-bottom: 0px;
}

.titel_our_blogs {
    text-align: end;
    margin-bottom: 2vw;
}

.titel_our_blogs h2 {
    color: #DA031B;
    font-weight: 700;
    font-size: 2vw;
    margin-bottom: 0px;
}

.titel_our_blogs p {
    font-size: 1vw;
    margin-bottom: 0px;
}

.boder {
    border-bottom: 2px solid #DEE1E6;
    padding-bottom: 20px;
    margin-bottom: 2vw;
}

.navigating_insurance h4 {
    font-size: 1vw;
    margin-top: 5px;
    font-weight: 600;
    margin-bottom: 0px;
}

.navigating_insurance img {
    width: 100%;
}

.home_view_more {
    background-color: #0066CC;
    padding: 0.5vw 2vw;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    box-shadow: 0px 5px #000;
}

.home_view_more:hover {
    box-shadow: 0px 5px #DA031B;
}

.home_view_more a {
    color: #ffff;
    text-decoration: none;
    font-size: 1.2vw;
    font-weight: 500;
}

.more_listed_company {
    height: max-content;
    margin: auto 0 0 0;

}

.ins-banner-one img {
    width: 26vw;
    float: right;
}

.insurance_services {
    text-align: end;
    position: relative;
    margin-bottom: 4vw;
    height: 100%;
}

.ins-banner-two {
    position: absolute;
    bottom: 0px;
    right: 160px;
    top: 4vw;
}

.ins-banner-two img {
    width: 32vw;
}

.ins-title-three {
    position: absolute;
    bottom: 0px;
    right: 35vw;
    background-color: #183B68;
    padding: 10px;
    width: 25vw;
}

.ins-title-three p {
    color: #fff;
    width: 12vw;
    text-align: center;
    margin: 0 auto;
    font-size: 1vw;
}

.ins-title-three span {
    font-weight: 500;
}

.get-bac-banner-top-5 {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.get_estimate_post {
    position: relative;
    height: max-content;
}

.get-bac-banner-4 img {
    width: 90%;
    margin: 0 auto;
}

.get-bac-banner-top-5 img {
    width: 90%;
    margin: auto;
}


@media screen and (max-width:768px) {
    .home_text p {
        font-size: 12px;
        width: 100%;
    }
    .insurance_services{
        margin-top: 50px;
        height: 70vw;
    }
    .ins-banner-one{
        position: relative;
        z-index: 1;
    }
    .ins-banner-one img{
        width: 40%;
    }
    .ins-banner-two img{
        width: 45vw;
    }
    .ins-title-three{
        width: unset;
        z-index: 3;
        bottom: 16vw;
    }
    .ins-title-three p{
        font-size: 10px;
        width: 100%;
       
    }
    .ins-banner-two{
        z-index: 2;
        right: 20vw;
        top: 7vw;
    }
    .home_blog_sec h6{
        font-size: 14px;
    }
    .get-bac-banner-4 img {
        width: 210px;
       
    }
    .get-bac-banner-top-5 img {
        width: 210px;
    }

    .testimonials_home h6 {
        font-size: 13px;
    }
    .slider-btn-top{
        display: none;
    }
    .titel_our_blogs{
        text-align: start;
    }

    .home_our_blogs {
        margin-bottom: 30px;
    }

    .home_page_call h6 {
        font-size: 12px;
    }

    .home_save_your_money_text {
        margin-bottom: 12px;
    }

    .home_page_call p {
        font-size: 12px;
    }

    .home_page_request a {
        font-size: 12px;
    }

    .home_page_request {
        width: 45%;
        padding: 0.5vw;
    }

    .group_logo img {
        width: 14%;
    }

    .group_logo h2 {
        font-size: 2vw;
        margin-bottom: 0px;
    }

    .home_about h6 {
        font-size: 15px;
    }

    .home_about h3 {
        font-size: 12px;
        margin-top: 10px;
    }

    .home_about p {
        font-size: 12px;
    }

    .home_save_your_money_text h5 {
        font-size: 12px;
        margin-top: 10px;
    }

    .home_save_your_money_text p {
        margin-bottom: 0px;
        font-size: 12px;
    }

    .Vehicle_insurance h3 {
        font-size: 14px;
        color: #fff;
    }

    .Vehicle_insurance p {
        font-size: 12px;
        color: #fff;
        margin-bottom: 1vw;
    }

    .home_insurance_slider_text h3 {
        font-size: 12px;
        font-weight: 600;
        margin-top: 7px;
    }

    .home_insurance_slider_text p {
        font-size: 12px;
    }

    .home_insurance_slider_text a {
        font-size: 12px;
    }

    .home_why_choose_us h6 {
        font-size: 12px;
    }

    .home_why_choose_us h4 {
        font-size: 13px;
        font-weight: 600;
        color: #DA031B;
        margin-bottom: 20px;
    }

    .trusted_partners_box h3 {
        color: #ffff;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .trusted_partners_box a {
        color: #ffff;
        font-size: 12px;
    }

    .get_free_estimate h3 {
        margin-top: 6vw;
        font-size: 12px;
    }

    .get_free_estimate h4 {
        font-size: 18px;
        font-weight: 600;
        color: #DA031B;
        margin-bottom: 20px;
    }

    .get_free_estimate_d_f {
        display: block;
    }

    .get_free_estimate {
        background-color: #EDEDED;
        width: 100%;
        margin: 0 auto;
        border-radius: 20px;
        margin-top: 5vw;
        padding: 35px 10px;
        margin-bottom: 7vw;
    }

    .Get_Quote button {
        background-color: #0066CC;
        padding: 5px;
        width: 45%;
        margin-top: 14px;
        border-radius: 10px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        border: none;
        box-shadow: 0px 5px #000;
    }

    .testimonials_insurance h3 {
        font-size: 14px;
        font-weight: 600;
        color: #DA031B;
    }

    .testimonials_insurance p {
        font-size: 12px;
        color: #000;
        margin-bottom: 1vw;
    }

    .home_our_blogs h3 {
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
    }

    .home_our_blogs p {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .titel_our_blogs h2 {
        color: #DA031B;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0px;
    }

    .titel_our_blogs p {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .navigating_insurance h4 {
        font-size: 12px;
        margin-top: 5px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .home_view_more a {
        color: #ffff;
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
    }

    .home_view_more {
        background-color: #0066CC;
        padding: 5px;
        border-radius: 15px;
        margin-bottom: 15px;
        width: 45%;
        text-align: center;
        box-shadow: 0px 5px #000;
    }

    .home_save_your_money_d_f {
        flex-wrap: wrap;
    }

    .trusted_partners_box:nth-child(2) {
        margin-bottom: 5vw;
    }

    
}