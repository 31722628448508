.our-team-container {
    background: linear-gradient(180deg, #2C5A95 0%, #0066CC 100%);

}

.our-team-heading {
    color: #fff;
    text-align: center;
    width: 60%;
    margin: 0 auto 5vw;
}

.our-team-heading h2 {
    font-size: 2.4vw;
    font-weight: 700;
    padding: 2vw;
}

.our-team-heading p {
    font-size: 1vw;
    font-weight: 600;
}

.our-team-img {
    overflow: hidden;
    border-radius: 50%;
    background-color: #20487a;
    margin: 0 auto;
   
    height: 170px;
    width: 170px;

}

.our-team-img img {
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.our-team-img img:hover {
    transform: scale(1.2);
}
.our-team-bottom-text{
    color: #fff;
    text-align: center;
    margin-top: 10px;
}
.our-team-bottom-text h3{
    font-size: 1.5vw;
    font-weight: 600;
}
.our-team-bottom-text p{
    font-size: 1vw;
    font-weight: 500;
}

@media screen and (max-width:768px) {
    .our-team-heading h2{
        font-size: 20px;
    }
    .our-team-heading{
        width: unset;
    }
    .our-team-heading p{
        font-size: 12px;
    }
    .our-team-wrapper{
        width: 85%;
    }
    .our-team-bottom-text h3{
        font-size: 16px;
    }
    .our-team-bottom-text p{
        font-size: 10px;
    }
}