.login-pages {
    height: 100vh;
}

.login-pages-d-f {
    display: flex;
    gap: 30px;
    width: 100%;
    margin-top: 3vw;
    justify-content: space-between;
}

.login-pages-logo img {
    width: 10%;
}

.login-pages-logo {
    padding-top: 2vw;
}

.login-pages-banner img {
    height: 100%;
    width: auto;
}

.login-pages-banner {
    width: 40%;
}

.login-pages-form {
    background-color: #fff;
    width: 55%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 2.43vw 4.51vw 1.39vw 4.51vw;
    border-radius: 10px;
    /* background-color: #fff; */
}

.login-pages-form h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 1vw;
}

.login-pages-form p {
    font-size: 16px;
    color: #183B68;
    margin-bottom: 4vw;

}

.enter-form-box {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-floating.login-label a {
    width: 100%;
    color: #000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.Login-btnn button {
    background-color: #183B68;
    width: max-content;

    display: flex;
    justify-content: center;
    padding: 8px 2vw;
    border-radius: 30px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto;
    box-shadow: lch(87 19.48 68.44) 0px 12px 8px;
}

.Login-btnn {
    margin-top: 2vw;
}

.login-pages-sign-with {
    margin-top: 2vw;
    text-align: center;
}

.sign-in-with-text a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 16px;
}

.sign-in-with-icen {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 1vw;
    margin-bottom: 1.5vw;
}

.sign-in-with-icen a {
    font-size: 30px;
}

.login-pages-sign-with p {
    font-size: 16px;
    width: 100% !important;
    margin-bottom: 0px;
}

a.login-sing-up {
    text-decoration: none;
    color: #183B68;
    font-size: 16px;
    font-weight: 600;
}

.my-login-form input {
    color: #000;
    margin-top: 20px;
}

.my-login-form input::placeholder {
    color: red;
}


.login_bac_img {
    position: absolute;
    z-index: -1;
   height: 100%;
    width: 60%;
}


/* signup css */


.sign-up-pages {
    height: 100vh;
}

.sign-up-pages-d-f {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
    margin-top: 1vw;
}

.login-pages-logo img {
    width: 10%;
}

.login-pages-logo {
    padding-top: 2vw;
}

.login-pages-banner img {
    width: 100%;
}

.sing-up-pages-banner {
    width: 45%;
    height: 40vw;
}

.sing-up-pages-banner img {
    width: 100%;
    height: 100%;
}

.sign-up-pages-form {
    width: 45%;
}

.sign-up-pages-form h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #000;
    margin-bottom: 5px;
}

.sign-up-pages-form h6 {
    font-size: 18px;
    color: #000;
    margin-bottom: 25px;
}

.sign-in-with-icen a {
    font-size: 30px;
}

.sign-up_bac_img {
    position: absolute;
    z-index: -1;
    display: block;
    width: 70%;
}

.sing-up-enter-form-box-dropdown {
    padding-top: 10px !important;
}

.sing-terms-condition i {
    font-size: 16px;
    color: #183B68;
}

.sing-terms-condition p {
    font-size: 16px;
    color: #787878;
    margin-bottom: 0px;
    padding-top: 20px;
}

.sing-terms-condition span {
    color: #DA031B;
}

.sing-up-btnn {
    margin-top: 2vw;
}

.sing-up-btnn button {
    background-color: #183B68;
    width: 25%;
    display: flex;
    justify-content: center;
    padding: 8px;
    border-radius: 30px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    margin: 2vw auto;
    box-shadow: lch(87 19.48 68.44) 0px 12px 8px;
}

.sing-up-already-account {
    margin-top: 2vw;
}

.sing-up-already-account h5 {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #787878;
}

.sing-up-already-account a {
    color: #183B68;
    font-size: 16px;
    font-weight: 600;
}

.login-main-container {
    width: 90%;
    max-width: 80vw;
    margin: auto;
}



@media screen and (max-width:768px) {

    .login-pages-d-f,
    .sign-up-pages-d-f {
        flex-direction: column;
    }

    .login-pages-banner,
    .login-pages-form,
    .sign-up-pages-form,
    .sing-up-pages-banner {
        width: 100%;
        margin: auto;
        height: unset;
    }

    .login-pages-logo img {
        width: 100px;
    }

    .Login-btnn button {
        padding: 2px 26px;
    }

    .login-pages-form p {
        font-size: 12px;
        margin: 20px 0;
    }

    .my-login-form input {
        margin-top: 7px;
    }

    .sing-up-btnn button {
        width: max-content;
        padding: 8px 26px;
    }

    .sing-up-btnn {
        margin: 25px 0;
    }
}