.achievements_background {
    background-image: url(../../../Images/Achievements/achievements-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 12vw;
}

.achievements_text {
    margin-top: 10vw;
}

.achievements_text h3 {
    /* font-size: 50px; */
    font-size: 2.5vw;
    color: #fff;
    font-weight: 600;
}

.achievements_text h4 {
    /* font-size: 50px; */
    font-size: 2.5vw;
    color: #fff;
    font-weight: 600;
}

.achievements_text p {
    font-size: 1vw;
    color: #fff;
    width: 50%;
}

.slick-swipe-slide {
    padding: 10px;
}

.slick-swipe-slide a {
    display: block;
    width: 100%;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    -webkit-box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
}

.slick-swipe-slide a:hover .image .overlay {
    opacity: 0.1;
}

.slick-swipe-slide a .image {

    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}

.slick-swipe-slide a .image .overlay {
    width: 100%;
    height: 100%;
    background-color: #DA031B;
    text-align: center;
    opacity: 0;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.slick-swipe-slide a .image .overlay em {
    color: #fff;
    font-size: 26px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
}

.swiper-slide-titel h3 {
    font-size: 1vw;
    font-weight: 700;
    margin-top: 13px;
}

.swiper-slide-titel p {
    font-size: 1vw;
    margin-bottom: 0px;
}

.swiper-slide-titel {
    border-left: 5px solid #DA031B;
    padding: 0px 0px 0px 10px;
}

.achievement-slide-container {
    padding: 5vw 0;
}

.card {
    scroll-snap-align: start;
    /* height: 340px; */
    height: auto;
    list-style: none;
    background: #fff;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    margin-right: 10px !important;
    width: 98%;
    padding: 20px;
    flex-direction: column;
}

.card h2 {
    font-weight: 600;
    font-size: 1vw;
    /* margin: 10px 0px 5px 0px; */
    color: #000;
    margin-bottom: 4px;
}

.card span {
    color: #6a6d78;
    font-size: 1vw;
}

.slider_icen a {
    font-size: 2vw;
    color: #DA031B;
    margin-bottom: 0px;
}

.achievements_about_background_titel p {
    font-size: 1vw;
    color: #fff;
    width: 60%;
    text-align: center;
    margin: 0 auto;
    padding: 0px 0px 30px 0px;
    margin-bottom: 0px;
}


.achievements_about_background {
    background-color: #2C5A95;
    padding: 30px 0px 30px 0px;
    margin-bottom: 30px;
}

@media screen and (max-width:767px) {
    .achievements_text p {
        font-size: 12px;
        color: #fff;
        width: 100%;
    }

    .achievements_text h3 {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
    }

    .achievements_text h4 {
        font-size: 14px;
        color: #fff;
        font-weight: 600;
    }

    .swiper-slide-titel h3 {
        font-size: 12px;
        font-weight: 700;
        margin-top: 13px;
    }

    .swiper-slide-titel p {
        font-size: 10px;
        margin-bottom: 0px;
    }

    .achievements_about_background_titel p {
        font-size: 12px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        padding: 0px 0px 20px 0px;
        margin-bottom: 0px;
    }

    .card h2 {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        margin-bottom: 4px;
    }

    .card span {
        color: #6a6d78;
        font-size: 10px;
    }

    .carousel .card {
        width: 100%;
    }

    .wrapper .icen_sli {
        height: 30px;
        width: 30px;
        background-color: #DA031B;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        top: 50%;
        font-size: 1.25 rem;
        transform: translateY(-50%);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
        z-index: 99999;
        font-size: 16px;
        color: #fff;
    }

    .slider_icen a {
        font-size: 16px;
    }
}



/* .image-container {
    width: 100%;
   
    overflow: hidden;
} */

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 100%; /* Ensure images fill their containers */
    height: 200px;
    object-fit: cover; /* Fill the container while preserving aspect ratio */
}