.nav_bac {
    background-color: #fff;
}

.home_navbar_top {
    padding-top: 2vw;
}

.home_navbar_top img {
    width: 150px;
}

.navbar_header {
    margin: 0 auto;
}

li.nav-item {
    padding: 0px 7px 0px 7px;
}

.home_navbar_top a {
    font-size: 18px;
    color: #000;
}

.home_navbar_top a:hover {
    color: #12445F;
}
.login_btnn {
    border: none;
    background-color: #E8E8E8;
    border-radius: 30px;
    font-size: 18px;
    color: #12445F !important;
    padding: 10px;
    text-decoration: none;
    font-weight: 600;
}
.home_btn a>svg{
    color: #12445F;
    border-radius: 50%;
    font-size: 18px;
}

