.help_center_background {
    background-image: url(../../../Images/HelpCentre/help-center-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 12vw;
}

.help-centre-main-container {
    background-color: #F4F5F8;
}

.help_center_text {
    margin-top: 5vw;
}

.help_center_text h2 {
    font-size: 3.2vw;
    font-family: "Marcellus";
    color: #fff;
    text-align: center;
}

.input-box {
    position: relative;
    width: 40%;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-top: 3vw;
}


.input-box .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #183B68;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.input-box .button svg {
    font-size: 20px;
    color: #fff;

}

.input-box input {
 padding:10px 16px;
    width: 100%;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    border: none;
    background-color: transparent;
   
}

.input-box .button {
    right: 10px;
    border: none;
    cursor: pointer;
}

.input-box .button:active {
    transform: translateY(-50%) scale(0.98);
}





.icen_help_bac {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.icen_help_bac a {
    font-size: 2vw;
    width: 40px;
    display: flex;
    margin-bottom: 16px;
    color: #DA031B;
}

.icen_help_bac h3 {
    font-size: 1.5vw;
    font-weight: 600;
}

.icen_help_bac p {
    font-size: 1vw;
    margin-bottom: 0px;
}

.insurance_help {
    margin-top: -60px;
}

.help_popular_articles {
    margin-top: 3vw;
}

.help_popular_articles h2 {
    font-size: 2vw;
    font-weight: 600;
    margin-bottom: 2vw;
}

.help_center_accordin {
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;
}

.help_still_question {
    margin-top: 3vw;
}

.help_still_question h3 {
    font-size: 2vw;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: center;
    color: #183B68;
}

.help_still_question p {
    font-size: 1vw;
    margin-bottom: 0px;
    text-align: center;
}

.question_help {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.still_help {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 25%;
    text-align: center;
}

.still_help a {
    font-size: 2vw;
    color: #183B68;
}

.still_help h4 {
    font-size: 1vw;
    font-weight: 500;
    margin-bottom: 0px;
}

.still_help p {
    font-size: 1vw;
    margin-bottom: 0px;
}

@media screen and (max-width:767px) {
    .icen_help_bac h3 {
        font-size: 14px;
        font-weight: 600;
    }

    .icen_help_bac p {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .help_still_question h3 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
        text-align: center;
        color: #183B68;
    }

    .help_still_question p {
        font-size: 12px;
        margin-bottom: 0px;
        text-align: center;
    }

    .question_help {
        display: block;
    }

    .still_help {
        width: 100%;
        margin-bottom: 10px;
    }

    .still_help h4 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .still_help p {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .help_popular_articles h2 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2vw;
    }

    .insurance_help {
        margin-top: -30px;
    }

    .input-box .button svg {
        font-size: 14px;
    }

    .input-box .button {

        width: 30px;
        height: 30px;
    }

    .input-box {
        position: relative;
        width: 100%;
    }

    .input-box input {
        padding-left: 7px;
        font-size: 12px;
    }

    .icen_help_bac a {
        font-size: 24px;
    }

    .still_help a {
        font-size: 16px;
    }
}