.Blog_title {
    margin-top: 5vw;
}
.blog-main-container{
    background-color: #F4F5F8;
}

.Blog_title h3 {
    font-size: 2.5vw;
    font-family: "Marcellus";
    color: #000;
    font-weight: 600;
}

p.blog_pad {
    margin-bottom: 25px;
    font-size: 1vw;
}

.Blog_title img {
    width: 100%;
}

.Blog_title h4 {
    font-size: 1.8vw;
    color: #000;
    margin-bottom: 20px;
}

.blog_next_ds {
    display: flex;
    gap: 20px;
    margin-top: 1vw;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.blog_next_ds h6 {
    font-size: 1vw;
    font-weight: 600;
    color: #9BB848;
}

.blog_next_ds h5 {
    font-size: 1vw;
    font-weight: 600;
    color: #48B8AA;
}

.blog_next_ds h2 {
    font-size: 1vw;
    font-weight: 600;
    color: #B89F48;
}

.Blog_title_text p {
    font-size: 1vw;
    margin-top: 0.5vw;
}

.Blog_title_text span {
    font-size: 0.8vw;
    font-weight: 800;
}

.blog_next_ds a {
    font-size: 0.8vw;
    color: #000;
}

.minutes_read a {
    color: #000;
    font-size: 0.8vw;
    padding-right: 10px;
}

.javascript_nextjs {
    margin-top: 4vw;
}

.javascript_nextjs_hover:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    width: 100%;
    transition: 0.8s;
}

.javascript_nextjs_hover {
    margin-bottom: 3vw;
}

.javascript_nextjs_title img {
    width: 100%;
}

.javascript_nextjs_title h3 {
    font-size: 1.2vw;
    color: #000;
    margin-top: 12px;
    font-weight: 600;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.javascript_nextjs_title p{
    font-size: 1vw;
    text-decoration: none;
    margin-top: 0.5vw;
    color: #000;
  text-align: justify;
   
}

.minutes_read span {
    font-size: 0.8vw;
    font-weight: 600;
}

.minutes_read h6 {
    font-size: 0.8vw;
    font-weight: 600;
    margin-bottom: 0px;
}

.minutes_clock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.javascript_nextjs_title {
    padding: 10px;
}

.blog_pagination {
    display: flex;
    justify-content: center;
    margin: 2vw 0;
}

.active>.page-link,
.page-link.active {
    color: var(--bs-pagination-active-color);
    background-color: #376BAE;
    border-color: #376BAE;
}

ul.pagination a {
    color: #376BAE;
    font-size: 16px;
    font-weight: 500;
}
.singleBlogcomponent-parent{
    text-decoration: none;
}
.blog-header-img img{
width: 100%;
}

@media screen and (max-width: 767px) {
 
    p.blog_pad {
        margin-bottom: 20px;
        font-size: 14px;
    }
 
    .Blog_title h4 {
        font-size: 1.8vw;
        margin-bottom: 0px;
        margin-top: 5px;
    }
    .Blog_title h3{
        font-size: 25px;
    }
 
    .Blog_title_text span {
        font-size: 12px;
        font-weight: 600;
    }
 
    .blog_next_ds h6 {
        font-size: 10px;
    }
 
    .blog_next_ds h5 {
        font-size: 10px;
    }
 
    .blog_next_ds h2 {
        font-size: 10px;
    }
 
    .Blog_title_text p {
        font-size: 14px;
        margin-bottom: 0px;
    }
 
    .blog_next_ds a {
        font-size: 12px;
    }
 
    .javascript_nextjs_title p{
        font-size: 14px;
        text-decoration: none;
        margin-top: 0vw;
        margin-bottom: 0px;
      
    }
 
    .minutes_read a {
        font-size: 12px;
    }
 
    .minutes_read span {
        font-size: 12px;
    }
 
    .minutes_read h6 {
        font-size: 12px;
    }
 
    .javascript_nextjs_title h3 {
        font-size: 20px;
    }
 
}