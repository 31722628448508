/* Insurance Type Cards */
.insurance_type_card {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: all 0.3s ease;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Development outline for better visibility */
.insurance_type_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Insurance Icons */
.insurance-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #2b4054; /* Your primary color */
}

/* Development Mode Outline */
.dev-mode .insurance_type_card {
    outline: 2px dashed #ff0000;
}

.insurance_type_card h4 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: #333;
}

.insurance_type_card p {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .insurance_type_card {
        margin-bottom: 15px;
    }
    
    .insurance-icon {
        font-size: 2rem;
    }
} 