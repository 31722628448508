.health-Insurance-background {
    background-image: linear-gradient(111.84deg, rgba(1, 57, 89, 0.91) 42.41%, rgba(115, 146, 164, 0.653055) 60.07%, rgba(255, 255, 255, 0.34) 74.54%, #FFFFFF 101.24%), url(../../../Images//Insurance/health-Insurance-background-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
}
 
.health-Insurance-home-banner-text {
    margin-top: 10vw;
    padding-bottom: 2vw;
}
 
.health-Insurance-home-banner-text h3 {
    font-size: 1.5vw;
    color: #fff;
}
 
.health-Insurance-home-banner-text h2 {
    font-size: 2.5vw;
    color: #fff;
    font-family: "Marcellus";
    width: 51%;
    margin-top: 1vw;
}
 
.health-Insurance-home-banner-text p {
    font-size: 1vw;
    color: #fff;
    width: 50%;
    margin-top: 1vw;
}
 
.health-Insurance-home-banner-btnn {
    background-color: #0066CC;
    text-align: center;
    padding: 10px;
    border-radius: 30px;
    width: 18%;
    box-shadow: 0px 5px #000;
    margin-top: 1vw;
}
 
.health-Insurance-home-banner-btnn a {
    color: #fff;
    text-decoration: none;
    font-size: 1vw;
    font-weight: 600;
    font-family: "Montserrat";
}
 
.health-Insurance-home-banner-btnn:hover {
    box-shadow: 0px 5px #EF3A43;
}
 
.Fast-Reliable-bac {
    background-color: #eaecef;
    padding: 15px 0px 15px 0px;
}
 
.health-Insurance-Trusted-Service {
    margin-top: 1vw;
    width: 100%;
}
 
.Fast-Reliable-icen {
    background-color: #2C5A95;
    border-radius: 50px;
    width: 4vw;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}
 
.tr-hea {
    display: flex;
    align-items: center;
}
 
.Fast-Reliable-icen>svg {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2vw;
}
 
.Fast-Reliable-text h2 {
    font-size: 1.6vw;
    font-weight: 600;
    margin-bottom: 0px;
}
 
.Fast-Reliable-text p {
    font-size: 16px;
    margin-bottom: 0px;
}
 
.health-Insurance-services {
    background-color: #F3EBEB;
    margin-top: 2vw;
    padding: 30px 0px 30px 0px;
}
 
.health-Insurance-services h4 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
}
 
.health-Insurance-services h2 {
    text-align: center;
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-top: 1vw;
}
 
.health-Insurance-services p {
    text-align: center;
    font-size: 16px;
    width: 55%;
    margin: 0 auto;
    color: #000;
}
 
.services-card-title {
    background-color: #265289;
    width: 100%;
    padding: 20px 10px 15px 20px;
    margin-top: 3vw;
    margin-right: 10px;
}
 
.services-card-title h3 {
    font-size: 1.6vw;
    color: #fff;
}
 
.services-card-title p {
    font-size: 16px;
    color: #fff;
    width: 80%;
    text-align: left;
    margin: initial;
    margin-bottom: 5px;
}
 
.services-card-title a {
    font-size: 20px;
    color: #fff;
}
 
.Advantages-title {
    margin-top: 2vw;
}
 
.Advantages-title-text h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 1vw;
}
 
.Advantages-title-text p {
    font-size: 16px;
    color: #000000;
}
 
.Advantages-title-banner img {
    width: 100%;
}
 
.Perfect-Health-Insurance-Plan-text h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 0px;
    text-align: center;
}
 
.Perfect-Health-Insurance-Plan-text p {
    font-size: 16px;
    color: #000000;
    text-align: center;
    width: 55%;
    margin: 0 auto;
}
 
.Perfect-Health-Insurance-Plan-text {
    margin-top: 2vw;
}
 
.individual-plan-1 {
    display: flex;
    background-color: #eaecef;
    border-radius: 10px;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
}
 
.individual-plan-family-plan-2 {
    margin-top: 2vw;
}
 
.individual-plan-1-banner img {
    width: 80%;
}
 
.individual-plan-1-banner {
    width: 70%;
}
 
.individual-plan-1-text {
    width: 100%;
}
 
.individual-plan-1-text h3 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #000;
}
 
.individual-plan-1-text p {
    font-size: 16px;
    margin-bottom: 0px;
    color: #000;
}
 
.individual-plan-btn-1 a {
    background-color: #0066CC;
    color: #fff;
    width: 60%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    border-radius: 10px;
}
 
.individual-plan-btn-1 {
    margin-top: 1vw;
}
 
.seamless-process-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, #21497d 50%, #3b68a2);
    padding: 10px 15px 0px 30px;
    border-radius: 10px;
}
 
.zero-documents-apply-text h3 {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
}
 
.zero-documents-apply-text h2 {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
}
 
.zero-documents-apply-text h4 {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0px;
    position: relative;
    bottom: 0;
    top: 50px;
}
 
.zero-documents-apply-text {
    width: 50%;
}
 
.zero-documents-apply-banner {
    width: 50%;
    text-align: end;
}
 
.zero-documents-apply-banner img {
    width: 55%;
    margin: 0 0 0 auto;
}
 
h6.faq-choose-health-insurance {
    font-size: 2vw;
    font-weight: 600;
    margin-bottom: 2vw;
    color: #183B68;
    font-family: "Marcellus";
}
 
.right-plans-protect {
    margin-top: 2vw;
}
 
.right-plans-protect h2 {
    font-size: 40px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 0px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
 
.right-plans-insurance-policies {
    margin: 2vw auto;
}
 
.right-plans-insurance-policies-title {
    background-color: #e6e9ec;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
}
 
.right-plans-insurance-policies-title img {
    width: 40%;
}
 
.right-plans-insurance-policies-title h2 {
    font-size: 22px;
    font-weight: 600;
    padding-top: 20px;
    margin-bottom: 0px;
    color: #000;
}
 
.right-plans-insurance-policies-title p {
    font-size: 16px;
    padding-top: 20px;
    margin-bottom: 0px;
    color: #000;
}
 
.right-plans-insurance-policies-title a {
    background-color: #0066CC;
    color: #fff;
    width: 60%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    border-radius: 10px;
    margin-top: 10px;
}
 
.help_popular_articles {
    margin-bottom: 50px;
}
 
.vehicle-insurance-background-banner {
    background-image: url(../../../Images/Insurance/vehicle-insurance-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
}
 
.Vehicle-insurance-right {
    margin-top: 2vw;
}
 
.Vehicle-insurance-background-1 {
    background-image: linear-gradient(#1f4980, #5889c9);
    padding: 30px;
}
 
.Vehicle-insurance-background-1 h2 {
    font-size: 35px;
    font-family: "Marcellus";
    color: #fff;
    margin-bottom: 0px;
    text-align: center;
}
 
.car-bike-two {
    display: flex;
    justify-content: center;
    gap: 2vw;
    margin-top: 2vw;
}
 
.car-bike-two a {
    color: #074089;
    text-decoration: none;
    font-size: 2vw;
    background-color: #fff;
    border-radius: 10px;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 1vw;
}
 
.vehicle-registration-2 {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 60%;
    margin: 0 auto;
    margin-top: 3vw;
}
 
.text-title-input {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.vehicle-registration-input {
    background-color: #EBEBEB;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #64607D;
    width: 85% !important;
}
 
.renew-existing-policy-get-price {
    display: flex;
    justify-content: center;
    gap: 2vw;
    margin-top: 2vw;
}
 
a.renew-existing-policy {
    color: #37474F;
    text-decoration: none;
    font-size: 16px;
    background-color: #EBEBEB;
    border-radius: 10px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
 
a.get-price {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    background-color: #0066CC;
    border-radius: 10px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
 
.insure-brand-new-car a {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
    text-decoration: none;
    color: #37474F;
    font-size: 20px;
    font-weight: 600;
    gap: 10px;
}
 
.insure-brand-new-car i {
    color: #0066CC;
}
 
.advantages-vehicle-insurance-background {
    margin-top: 2vw;
}
 
.advantages-vehicle-insurance-background h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 15px;
}
 
.advantages-vehicle-insurance-title p {
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
    line-height: 30px;
}
 
.advantages-vehicle-insurance-banner img {
    width: 100%;
}
 
.right-plans-protect {
    margin-top: 2vw;
}
 
.right-plans-protect h2 {
    font-size: 40px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 0px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
 
@media screen and (max-width:767px) {
    .Fast-Reliable-bac {
        margin-bottom: 20px;
    }
 
    .health-Insurance-home-banner-btnn {
        padding: 0 20px;
        width: max-content;
 
    }
 
    .health-Insurance-home-banner-btnn a {
        font-size: 12px;
    }
 
    .Fast-Reliable-icen>svg {
 
        font-size: 17px;
    }
 
    .Fast-Reliable-icen {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
 
    .health-Insurance-home-banner-text h3 {
        font-size: 9px;
    }
 
    .health-Insurance-home-banner-text h2 {
        font-size: 16px;
        width: 80%;
    }
 
    .health-Insurance-home-banner-text {
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
 
    .health-Insurance-home-banner-text p {
        font-size: 10px;
        width: unset;
    }
 
    .Vehicle-insurance-background-1 h2 {
        font-size: 20px;
    }
 
    .tr-hea {
        display: block;
    }
 
    .Fast-Reliable-text {
        padding-top: 10px;
    }
 
    .Fast-Reliable-text h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
        text-align: center;
    }
 
    .Fast-Reliable-text p {
        font-size: 16px;
        margin-bottom: 0px;
        text-align: center;
    }
 
    .services-card-title h3 {
        font-size: 18px;
        color: #fff;
    }
 
    .Advantages-title-banner img {
        width: 100%;
        margin-bottom: 20px;
    }
 
    .individual-plan-1 {
        display: block;
    }
 
    .individual-plan-1-banner {
        width: 100%;
    }
 
    .seamless-process-1 {
        display: block;
    }
 
    .zero-documents-apply-text {
        width: 100% !important;
    }
 
    .zero-documents-apply-banner {
        width: 100% !important;
    }
 
    .right-plans-insurance-policies-title {
        margin-bottom: 20px;
    }
 
    .car-bike-two a {
        width: max-content;
        font-size: 12px;
    }
 
    a.renew-existing-policy {
        width: max-content !important;
    }
 
    .renew-existing-policy-get-price {
        flex-direction: column;
        align-items: center;
    }
 
    .Vehicle-insurance-background-1 {
        padding: 20px;
    }
    .health-Insurance-services h4{
        font-size: 20px;
    }
    .health-Insurance-services h2{
        font-size: 24px;
 
    }
    .health-Insurance-services p{
        font-size: 12px;
    }
    .advantages-vehicle-insurance-banner{
        margin-top: 30px;
    }
    .right-plans-protect h2{
        font-size: 24px;
        margin: 20px auto;
    }
    .zero-documents-apply-text h3{
        font-size: 20px;
    }
    .zero-documents-apply-text h2{
        font-size: 24px;
    }
    .zero-documents-apply-text{
        margin-bottom: 50px;
 
    }
    h6.faq-choose-health-insurance{
        font-size: 24px;
        margin-top: 25px;
    }
    .Perfect-Health-Insurance-Plan-text h2{
        margin-bottom: 20px;
    }
    .zero-documents-apply-banner img {
        width: 70% !important;
        margin: 0 auto;
    }
    .footer_icen a>svg {
        font-size: 28px;
    }
}
 
@media screen and (max-width: 1399px) {
 
 
    /* vehicle-insurance */
    .zero-documents-apply-text h4 {
        top: 0px;
    }
 
 
}
 
@media screen and (max-width: 1199px) {
 
    /* vehicle-insurance */
 
    a.renew-existing-policy {
        width: 50%;
    }
 
    a.get-price {
        width: 50%;
    }
 
    .vehicle-registration-input {
        width: 100% !important;
    }
 
    .right-plans-insurance-policies-title h2 {
        font-size: 16px;
    }
 
    .right-plans-protect h2 {
        width: 100%;
    }
}
 
.home-insurance-background-banner {
    background-image: url(../../../Images//Insurance/home-insurance-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
}
 
.home-insurance-bac-2 {
    margin-top: 2vw;
}
 
.home-insurance-bac-titel-2 p {
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    line-height: 30px;
}
 
.home-insurance-bac-banner-2 img {
    width: 100%;
    margin-bottom: 15px;
}
 
.my-custom-slide {
    display: flex !important;
 
}
 
 
@media screen and (max-width: 1023px) {
 
 
 
 
    .health-Insurance-services p {
        width: 100%;
    }
 
    .Perfect-Health-Insurance-Plan-text p {
        width: 100%;
    }
 
    .individual-plan-1-banner img {
        width: 100%;
    }
 
    .individual-plan-btn-1 a {
        width: 100%;
    }
 
    .zero-documents-apply-text {
        width: 70%;
    }
 
    .zero-documents-apply-banner {
        width: 30%;
    }
 
 
    .zero-documents-apply-banner img {
        width: 100%;
    }
 
    .zero-documents-apply-text h4 {
        top: 0px;
    }
 
    /* vehicle-insurance */
 
    .right-plans-insurance-policies-title img {
        width: 50%;
    }
 
    .right-plans-insurance-policies-title a {
        width: 100%;
    }
 
    .vehicle-registration-2 {
        width: 100%;
    }
 
    /* home-insurance */
 
   
 
 
}.health-Insurance-background {
    background-image: linear-gradient(111.84deg, rgba(1, 57, 89, 0.91) 42.41%, rgba(115, 146, 164, 0.653055) 60.07%, rgba(255, 255, 255, 0.34) 74.54%, #FFFFFF 101.24%), url(../../../Images//Insurance/health-Insurance-background-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
}
 
.health-Insurance-home-banner-text {
    margin-top: 10vw;
    padding-bottom: 2vw;
}
 
.health-Insurance-home-banner-text h3 {
    font-size: 1.5vw;
    color: #fff;
}
 
.health-Insurance-home-banner-text h2 {
    font-size: 2.5vw;
    color: #fff;
    font-family: "Marcellus";
    width: 51%;
    margin-top: 1vw;
}
 
.health-Insurance-home-banner-text p {
    font-size: 1vw;
    color: #fff;
    width: 50%;
    margin-top: 1vw;
}
 
.health-Insurance-home-banner-btnn {
    background-color: #0066CC;
    text-align: center;
    padding: 10px;
    border-radius: 30px;
    width: 18%;
    box-shadow: 0px 5px #000;
    margin-top: 1vw;
}
 
.health-Insurance-home-banner-btnn a {
    color: #fff;
    text-decoration: none;
    font-size: 1vw;
    font-weight: 600;
    font-family: "Montserrat";
}
 
.health-Insurance-home-banner-btnn:hover {
    box-shadow: 0px 5px #EF3A43;
}
 
.Fast-Reliable-bac {
    background-color: #eaecef;
    padding: 15px 0px 15px 0px;
}
 
.health-Insurance-Trusted-Service {
    margin-top: 1vw;
    width: 100%;
}
 
.Fast-Reliable-icen {
    background-color: #2C5A95;
    border-radius: 50px;
    width: 4vw;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}
 
.tr-hea {
    display: flex;
    align-items: center;
}
 
.Fast-Reliable-icen>svg {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2vw;
}
 
.Fast-Reliable-text h2 {
    font-size: 1.6vw;
    font-weight: 600;
    margin-bottom: 0px;
}
 
.Fast-Reliable-text p {
    font-size: 16px;
    margin-bottom: 0px;
}
 
.health-Insurance-services {
    background-color: #F3EBEB;
    margin-top: 2vw;
    padding: 30px 0px 30px 0px;
}
 
.health-Insurance-services h4 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
}
 
.health-Insurance-services h2 {
    text-align: center;
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-top: 1vw;
}
 
.health-Insurance-services p {
    text-align: center;
    font-size: 16px;
    width: 55%;
    margin: 0 auto;
    color: #000;
}
 
.services-card-title {
    background-color: #265289;
    width: 100%;
    padding: 20px 10px 15px 20px;
    margin-top: 3vw;
    margin-right: 10px;
}
 
.services-card-title h3 {
    font-size: 1.6vw;
    color: #fff;
}
 
.services-card-title p {
    font-size: 16px;
    color: #fff;
    width: 80%;
    text-align: left;
    margin: initial;
    margin-bottom: 5px;
}
 
.services-card-title a {
    font-size: 20px;
    color: #fff;
}
 
.Advantages-title {
    margin-top: 2vw;
}
 
.Advantages-title-text h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 1vw;
}
 
.Advantages-title-text p {
    font-size: 16px;
    color: #000000;
}
 
.Advantages-title-banner img {
    width: 100%;
}
 
.Perfect-Health-Insurance-Plan-text h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 0px;
    text-align: center;
}
 
.Perfect-Health-Insurance-Plan-text p {
    font-size: 16px;
    color: #000000;
    text-align: center;
    width: 55%;
    margin: 0 auto;
}
 
.Perfect-Health-Insurance-Plan-text {
    margin-top: 2vw;
}
 
.individual-plan-1 {
    display: flex;
    background-color: #eaecef;
    border-radius: 10px;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
}
 
.individual-plan-family-plan-2 {
    margin-top: 2vw;
}
 
.individual-plan-1-banner img {
    width: 80%;
}
 
.individual-plan-1-banner {
    width: 70%;
}
 
.individual-plan-1-text {
    width: 100%;
}
 
.individual-plan-1-text h3 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #000;
}
 
.individual-plan-1-text p {
    font-size: 16px;
    margin-bottom: 0px;
    color: #000;
}
 
.individual-plan-btn-1 a {
    background-color: #0066CC;
    color: #fff;
    width: 60%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    border-radius: 10px;
}
 
.individual-plan-btn-1 {
    margin-top: 1vw;
}
 
.seamless-process-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, #21497d 50%, #3b68a2);
    padding: 10px 15px 0px 30px;
    border-radius: 10px;
}
 
.zero-documents-apply-text h3 {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
}
 
.zero-documents-apply-text h2 {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
}
 
.zero-documents-apply-text h4 {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0px;
    position: relative;
    bottom: 0;
    top: 50px;
}
 
.zero-documents-apply-text {
    width: 50%;
}
 
.zero-documents-apply-banner {
    width: 50%;
    text-align: end;
}
 
.zero-documents-apply-banner img {
    width: 55%;
    margin: 0 0 0 auto;
}
 
h6.faq-choose-health-insurance {
    font-size: 2vw;
    font-weight: 600;
    margin-bottom: 2vw;
    color: #183B68;
    font-family: "Marcellus";
}
 
.right-plans-protect {
    margin-top: 2vw;
}
 
.right-plans-protect h2 {
    font-size: 40px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 0px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
 
.right-plans-insurance-policies {
    margin: 2vw auto;
}
 
.right-plans-insurance-policies-title {
    background-color: #e6e9ec;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
}
 
.right-plans-insurance-policies-title img {
    width: 40%;
}
 
.right-plans-insurance-policies-title h2 {
    font-size: 22px;
    font-weight: 600;
    padding-top: 20px;
    margin-bottom: 0px;
    color: #000;
}
 
.right-plans-insurance-policies-title p {
    font-size: 16px;
    padding-top: 20px;
    margin-bottom: 0px;
    color: #000;
}
 
.right-plans-insurance-policies-title a {
    background-color: #0066CC;
    color: #fff;
    width: 60%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    border-radius: 10px;
    margin-top: 10px;
}
 
.help_popular_articles {
    margin-bottom: 50px;
    padding-top: 40px;
}
 
.vehicle-insurance-background-banner {
    background-image: url(../../../Images/Insurance/vehicle-insurance-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
}
 
.Vehicle-insurance-right {
    margin-top: 2vw;
}
 
.Vehicle-insurance-background-1 {
    background-image: linear-gradient(#1f4980, #5889c9);
    padding: 30px;
}
 
.Vehicle-insurance-background-1 h2 {
    font-size: 35px;
    font-family: "Marcellus";
    color: #fff;
    margin-bottom: 0px;
    text-align: center;
}
 
.car-bike-two {
    display: flex;
    justify-content: center;
    gap: 2vw;
    margin-top: 2vw;
}
 
.car-bike-two a {
    color: #074089;
    text-decoration: none;
    font-size: 2vw;
    background-color: #fff;
    border-radius: 10px;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 1vw;
}
 
.vehicle-registration-2 {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 60%;
    margin: 0 auto;
    margin-top: 3vw;
}
 
.text-title-input {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.vehicle-registration-input {
    background-color: #EBEBEB;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #64607D;
    width: 85% !important;
}
 
.renew-existing-policy-get-price {
    display: flex;
    justify-content: center;
    gap: 2vw;
    margin-top: 2vw;
}
 
a.renew-existing-policy {
    color: #37474F;
    text-decoration: none;
    font-size: 16px;
    background-color: #EBEBEB;
    border-radius: 10px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
 
a.get-price {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    background-color: #0066CC;
    border-radius: 10px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
 
.insure-brand-new-car a {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
    text-decoration: none;
    color: #37474F;
    font-size: 20px;
    font-weight: 600;
    gap: 10px;
}
 
.insure-brand-new-car i {
    color: #0066CC;
}
 
.advantages-vehicle-insurance-background {
    margin-top: 2vw;
}
 
.advantages-vehicle-insurance-background h2 {
    font-size: 32px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 15px;
}
 
.advantages-vehicle-insurance-title p {
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
    line-height: 30px;
}
 
.advantages-vehicle-insurance-banner img {
    width: 100%;
}
 
.right-plans-protect {
    margin-top: 2vw;
}
 
.right-plans-protect h2 {
    font-size: 40px;
    font-family: "Marcellus";
    color: #183B68;
    margin-bottom: 0px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
 
@media screen and (max-width:767px) {
    .Fast-Reliable-bac {
        margin-bottom: 20px;
    }
 
    .health-Insurance-home-banner-btnn {
        padding: 0 20px;
        width: max-content;
 
    }
 
    .health-Insurance-home-banner-btnn a {
        font-size: 12px;
    }
 
    .Fast-Reliable-icen>svg {
 
        font-size: 17px;
    }
 
    .Fast-Reliable-icen {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
 
    .health-Insurance-home-banner-text h3 {
        font-size: 9px;
    }
 
    .health-Insurance-home-banner-text h2 {
        font-size: 16px;
        width: 80%;
    }
 
    .health-Insurance-home-banner-text {
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
 
    .health-Insurance-home-banner-text p {
        font-size: 10px;
        width: unset;
    }
 
    .Vehicle-insurance-background-1 h2 {
        font-size: 20px;
    }
 
    .tr-hea {
        display: block;
    }
 
    .Fast-Reliable-text {
        padding-top: 10px;
    }
 
    .Fast-Reliable-text h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
        text-align: center;
    }
 
    .Fast-Reliable-text p {
        font-size: 16px;
        margin-bottom: 0px;
        text-align: center;
    }
 
    .services-card-title h3 {
        font-size: 18px;
        color: #fff;
    }
 
    .Advantages-title-banner img {
        width: 100%;
        margin-bottom: 20px;
    }
 
    .individual-plan-1 {
        display: block;
    }
 
    .individual-plan-1-banner {
        width: 100%;
    }
 
    .seamless-process-1 {
        display: block;
    }
 
    .zero-documents-apply-text {
        width: 100% !important;
    }
 
    .zero-documents-apply-banner {
        width: 100% !important;
    }
 
    .right-plans-insurance-policies-title {
        margin-bottom: 20px;
    }
 
    .car-bike-two a {
        width: max-content;
        font-size: 12px;
    }
 
    a.renew-existing-policy {
        width: max-content !important;
    }
 
    .renew-existing-policy-get-price {
        flex-direction: column;
        align-items: center;
    }
 
    .Vehicle-insurance-background-1 {
        padding: 20px;
    }
    .health-Insurance-services h4{
        font-size: 20px;
    }
    .health-Insurance-services h2{
        font-size: 24px;
 
    }
    .health-Insurance-services p{
        font-size: 12px;
    }
    .advantages-vehicle-insurance-banner{
        margin-top: 30px;
    }
    .right-plans-protect h2{
        font-size: 24px;
        margin: 20px auto;
    }
    .zero-documents-apply-text h3{
        font-size: 20px;
    }
    .zero-documents-apply-text h2{
        font-size: 24px;
    }
    .zero-documents-apply-text{
        margin-bottom: 50px;
 
    }
    h6.faq-choose-health-insurance{
        font-size: 24px;
        margin-top: 25px;
    }
    .Perfect-Health-Insurance-Plan-text h2{
        margin-bottom: 20px;
    }
    .zero-documents-apply-banner img {
        width: 70% !important;
        margin: 0 auto;
    }
    .footer_icen a>svg {
        font-size: 28px;
    }
}
 
@media screen and (max-width: 1399px) {
 
 
    /* vehicle-insurance */
    .zero-documents-apply-text h4 {
        top: 0px;
    }
 
 
}
 
@media screen and (max-width: 1199px) {
 
    /* vehicle-insurance */
 
    a.renew-existing-policy {
        width: 50%;
    }
 
    a.get-price {
        width: 50%;
    }
 
    .vehicle-registration-input {
        width: 100% !important;
    }
 
    .right-plans-insurance-policies-title h2 {
        font-size: 16px;
    }
 
    .right-plans-protect h2 {
        width: 100%;
    }
}
 
.home-insurance-background-banner {
    background-image: url(../../../Images//Insurance/home-insurance-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
}
 
.home-insurance-bac-2 {
    margin-top: 2vw;
}
 
.home-insurance-bac-titel-2 p {
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    line-height: 30px;
}
 
.home-insurance-bac-banner-2 img {
    width: 100%;
    margin-bottom: 15px;
}
 
.my-custom-slide {
    display: flex !important;
 
}
 
 
@media screen and (max-width: 1023px) {
 
 
    .health-Insurance-services p {
        width: 100%;
    }
 
    .Perfect-Health-Insurance-Plan-text p {
        width: 100%;
    }
 
    .individual-plan-1-banner img {
        width: 100%;
    }
 
    .individual-plan-btn-1 a {
        width: 100%;
    }
 
    .zero-documents-apply-text {
        width: 70%;
    }
 
    .zero-documents-apply-banner {
        width: 30%;
    }
 
 
    .zero-documents-apply-banner img {
        width: 100%;
    }
 
    .zero-documents-apply-text h4 {
        top: 0px;
    }
 
    /* vehicle-insurance */
 
    .right-plans-insurance-policies-title img {
        width: 50%;
    }
 
    .right-plans-insurance-policies-title a {
        width: 100%;
    }
 
    .vehicle-registration-2 {
        width: 100%;
    }
 
    /* home-insurance */
 
   
 
 
}