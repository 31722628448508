.footer {
    background-image: linear-gradient(180deg, #224B7F, #3B71B7);
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer_background {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    margin: 0 auto;
}

.footer_title img {
    width: 85%;
}

.footer_title p {
    padding-top: 1vw;
    font-size: 1vw;
}

.footer_title h2 {
    font-size: 1.7vw;
    font-weight: 600;
    color: #183B68;
    margin-bottom: 16px;
}

.footer_title li {
    margin-bottom: 10px;
    list-style: none;
}

.footer_title li a {
    text-decoration: none;
    font-size: 1vw;
    color: #000000;
    font-weight: 500;
}

.footer_icen a>svg{
    font-size: 2vw;
    padding-right: 10px;
    color: #3467A8;
}

.footer_icen {
    margin-top: 2vw;
}

.footer hr {
    border: 1px solid #fff;
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
}

.footer_last_title p {
    font-size: 1vw;
    color: #fff;
    margin-bottom: 5px;
}


.footer_last {
    width: 80%;
    margin: 0 auto;
}
.footer_last_title{
    display: flex;
}
.footer_last_title a {
    color: #fff;
    text-decoration: underline !important;
    text-decoration: none;
    font-size: 1vw;
    margin-left: 8px;
}

.footer_last_title1 span {
    display: flex;
    justify-content: flex-end;
}

.footer_last_title1 span {
    font-size: 1vw;
    color: #fff;
}

.footer_last_title1 a {
    font-size: 1vw;
    color: #fff;
    text-decoration: underline !important;
}

@media screen and (max-width:767px) {
    .footer_title p {
        padding-top: 1vw;
        font-size: 14px;
    }

    .footer_title h2 {
        font-size: 15px;
        ;
        margin-bottom: 0px;
    }

    .footer_title li a {
        font-size: 12px;
    }

    .footer_title li {
        margin-bottom: 5px;
    }

    .footer_icen i {
        font-size: 12px;
        padding-right: 5px;
    }

    .footer hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .footer_last_title p {
        font-size: 14px;
    }

    .footer_last_title a {
        font-size: 12px;
    }

    .footer_last_title1 span {
        font-size: 12px;
    }

    .footer_last_title1 a {
        font-size: 12px;
    }

    .footer_last_title1 span {
        display: flex;
        justify-content: flex-start;
        margin-top: 4px;
    }
}
