.consectetur_adipiscing {
    padding-top: 5vw;
}

.consectetur_adipiscing h3 {
    font-size: 1.9vw;
    color: #000;
}

.jordan_mirchev_background {
    margin-top: 2vw;
}

.jordan_mirchev {
    display: flex;
    align-items: center;
    gap: 10px;
}

.jordan_mirchev_banner img {
    width: 100%;
}

.jordan_mirchev_text h5 {
    font-size: 0.8vw;
    margin-bottom: 0px;
    color: #000;
}

.feature_banner img {
    width: 100%;
}

.feature_banner {
    margin-top: 1vw;
}

.blog_detail_titel {
    margin-top: 2vw;
}

.blog_detail_titel p {
    font-size: 1vw;
    color: #000;
    text-align: justify;
}

.blog_detail_titel h2 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 18px;
    margin-top: 20px;
}

.Popular_Posts {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 2vw;
    border-bottom: 2px solid #172B4D;
    padding-bottom: 30px;
}

.pizza_banner img {
    width: 100%;
}

.Product h4 {
    font-size: 0.7vw;
    color: #42526E;
    font-weight: 500;
    text-transform: uppercase;
}

.Product h5 {
    font-size: 1vw;
    margin-bottom: 0px;
    color: #172B4D;
}

.blog_detail_titel h6 {
    font-size: 0.7vw;
    color: #42526E;
    font-weight: 500;
    text-transform: uppercase;
}

.done_together {
    background-color: #FCA130;
    border-radius: 10px;
    padding: 2vw;
    margin-top: 2vw;
}

.blog_detail_get_started {
    text-align: center;
    margin-top: 2vw;
}

.blog_detail_get_started a {
    background-color: #DEEBFF;
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    color: #000;
    font-size: 1.1vw;
    font-weight: 500;
}

.done_together h3 {
    font-size: 1.6vw;
    color: #fff;
    text-align: center;
}

.done_together span {
    text-align: center;
    display: flex;
    margin-top: 2vw;
    font-size: 1vw;
    color: #fff;
}

.find_interesting_titel h4 {
    font-size: 0.7vw;
    color: #42526E;
    font-weight: 500;
    margin-top: 13px;
    text-transform: uppercase;
}

.find_interesting {
    margin-top: 2vw;
}

.find_interesting h2 {
    text-transform: uppercase;
    font-size: 0.8vw;
    margin-bottom: 30px;
    font-weight: 400;
}

.find_interesting_titel img {
    width: 100%;
}

.find_interesting_titel h3 {
    font-size: 1.2vw;
}

.find_interesting_titel p {
    font-size: 1vw;
    text-align: justify;
}

.find_interesting_titel {
    margin-bottom: 2vw;
}

.my {
    background-color: #F4F5F8;
}

@media screen and (max-width: 767px) {

    .jordan_mirchev_text h5 {
        font-size: 1.6vw;
    }

    .consectetur_adipiscing h3 {
        font-size: 2.5vw;
    }

    .blog_detail_titel p {
        font-size: 14px;
    }

    .blog_detail_titel h2 {
        font-size: 3vw;
    }

    .blog_detail_titel h6 {
        font-size: 10px;
    }

    .Product h4 {
        font-size: 10px;
    }

    .Product h5 {
        font-size: 12px;
    }

    .Popular_Posts {
        border-bottom: 1px solid #172B4D;
        padding-bottom: 15px;
    }

    .done_together h3 {
        font-size: 15px;
    }

    .done_together span {
        font-size: 12px;
    }

    .blog_detail_get_started a {
        font-size: 14px;
        padding: 5px 10px;
    }

    .find_interesting h2 {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .find_interesting_titel h4 {
        font-size: 14px;
        margin-top: 5px;
    }

    .find_interesting_titel h3 {
        font-size: 13px;
    }

    .find_interesting_titel p {
        font-size: 14px;
    }


}