.contact_us_background {
    background-image: url(../../../Images/ContactUs/contact-us-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 12vw;
}

.contact_us_text h2 {
    font-size: 3.2vw;
    font-family: "Marcellus";
    color: #fff;
}

.contact_us_text p {
    font-size: 1vw;
    color: #fff;
    margin-bottom: 0px;
    width: 35%;
}

.contact_us_text {
    margin-top: 5vw;
}

.contact_us_icen {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 2vw;
}

.contact_touch h3 {
    font-size: 2vw;
    margin-bottom: 0px;
    font-family: "Marcellus";
    color: #183B68;
}

.cont_mt {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.contact_us_icen a {
    background-color: #fff;
    border-radius: 10px;
    width: 3%;
    height: 2.6vw;
    text-align: center;
    font-size: 1.4vw;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.contact_input_box_bac {
    background-color: #2C5A95;
    margin-top: 2vw;
    border-radius: 15px;
    padding: 20px 0px 30px 0px;
}

.contact_touch {
    margin-top: 3vw;
}

.contact_button {
    background-color: #fff;
    width: 15%;
    margin-left: 7vw;
    text-align: center;
    padding: 8px;
    border-radius: 15px;
    margin-top: 2vw;
    box-shadow: 0px 5px #000;
}

.contact_button:hover {
    background-color: #fff;
    box-shadow: 0px 5px #DA031B;
}

.contact_button a {
    color: #183B68;
    text-decoration: none;
    font-size: 1vw;
    font-weight: 500;
}

.contact_input_box_bac .form-label {
    color: #fff;
    width: 100%;
}

.contact_corporate_office {
    background-color: #efefef;
    margin-top: 3vw;
    margin-bottom: 2vw;
}

.contact_corporate_office a {
    font-size: 1.4vw;
    color: #183B68;
}

.contact_corporate_office h6 {
    font-size: 1vw;
    color: #000;
    margin-bottom: 0px;
    font-weight: 500;
}

.contact_corporate_office p {
    font-size: 0.9vw;
    color: #000;
}

.cont_last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.contact_last_icen {
    width: 100%;
}


@media screen and (max-width: 767px) {

   

    .cont_last {
       flex-direction: column;
       gap: 20px;
        padding-top: 0px;
    }

    .contact_touch h3 {
        font-size: 16px;
    }

    .contact_button {
        width: 45%;
        margin-left: 3vw;
        
    }

    .contact_corporate_office a {
        font-size: 12px;
        color: #183B68;
    }

    .contact_corporate_office h6 {
        font-size: 14px;
    }

    .contact_corporate_office p {
        font-size: 12px;
        color: #000;
        margin-bottom: 0px;
    }
    .contact_us_text p {
        width: 100%;
        font-size: 12px;
    }
 
    .contact_us_icen {
        margin-top: 1vw;
    }
 
    .contact_us_icen a {
        width: 7%;
        height: 4.7vw;
        font-size: 12px;
    }
 
    .insurance_help {
        margin-top: -30px;
    }
    .contact_button a {
        font-size: 12px;
    }
}

    